import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    category: [],
    initialized: false,
    product: [],
    subcategory: [],
};

const productStateReducer = createSlice({
    initialState,
    name: 'productState',
    reducers: {
        initState(state, action) {
            state.category = action.payload.category;
            state.subcategory = action.payload.subcategory;
            state.initialized = true;
        },
        resetState() {
            return initialState;
        },
        toggleItem(state, action) {
            const item = state[action.payload.type].find((elem) => elem === action.payload.id);
            if (item) {
                const newArray = [...state[action.payload.type].filter((elem) => elem !== action.payload.id)];
                return { ...state, [action.payload.type]: newArray };
            } else {
                state[action.payload.type].push(action.payload.id);
            }
        },
    },
});

export const { initState, resetState, toggleItem } = productStateReducer.actions;

export default productStateReducer.reducer;
