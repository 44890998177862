import React, { createContext, useContext, useState } from 'react';

const noop = () => {};
const defaultValue = { isOpen: false, toggleSidebar: noop };

const SidebarContext = createContext(defaultValue);

const SidebarContextProvider = (props) => {
    const [open, setOpen] = useState(false);

    const values = {
        isOpen: open,
        toggleSidebar: (state) => setOpen(state),
    };

    return <SidebarContext.Provider value={values} {...props} />;
};

const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (context === defaultValue) {
        throw new Error('useSidebar must be used within SidebarContextProvider');
    }
    return context;
};

export { SidebarContextProvider, useSidebar };
