import { combineReducers } from 'redux';

import databasesReducer from '../database/reducer';
import filtersReducer from '../views/components/ProductListFilterButtons/reducer';
import selectorFilterReducer from '../views/components/ProductListFilterButtons/selectorFilterReducer';
import cartReducer from '../views/features/cart/reducer';
import diagnosticReducer from '../views/features/diagnostic/reducer';
import productStateReducer from '../views/features/productState/reducer';
import adminReducer from './admin';

export default combineReducers({
    admin: adminReducer,
    cart: cartReducer,
    databases: databasesReducer,
    diagnostic: diagnosticReducer,
    filters: filtersReducer,
    productState: productStateReducer,
    selectorFilter: selectorFilterReducer,
});
