/* eslint-disable no-undef */
import find from 'pouchdb-find';

import { setRemainingEntries, setTotalEntries } from './reducer';

const ip = process.env.GATSBY_DB_IP;

const distantDbPathBase = ip.includes('http://') || ip.includes('https://') ? `${ip}/` : `https://${ip}/`;

export const initMainDB = ({ name, password, username }) => {
    return new Promise(async (resolve, reject) => {
        try {
            await replicateFromSource({
                localName: 'kioskDB',
                password,
                sourceName: name,
                username,
            });
            resolve(true);
        } catch (error) {
            reject(error);
        }
    });
};

export const initProductDB = ({ name, password, username }, { dispatch }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { localDB } = await replicateFromSource({
                dispatch,
                localName: 'productDB',
                password,
                sourceName: name,
                username,
            });

            console.info('creating name index');
            await localDB.createIndex({
                index: {
                    fields: ['name'],
                },
            });
            console.info('name index was built!');

            console.info('creating multi index cat');
            await localDB.createIndex({
                index: {
                    fields: ['sub_category.id', 'tag_lpp', 'tag_loc', 'tag_charge_max', '_id'],
                },
            });
            console.info('multi index was built!');
            resolve(true);
        } catch (error) {
            console.error('replicateDB -> error', error);
            reject(error);
        }
    });
};

export const initCentralizedImagesDB = ({ password, username }, { dispatch }) => {
    return new Promise(async (resolve, reject) => {
        try {
            await replicateFromSource({
                dispatch,
                localName: 'centralizedImagesDB',
                password,
                sourceName: 'centralized_images',
                username,
            });
            resolve(true);
        } catch (error) {
            reject(error);
        }
    });
};

export const initClientDB = ({ name, password, username }, { dispatch }) => {
    return new Promise(async (resolve, reject) => {
        try {
            await replicateFromSource({
                dispatch,
                localName: 'clientDB',
                password,
                sourceName: name,
                username,
            });

            resolve(true);
        } catch (error) {
            reject(error);
        }
    });
};

const replicateFromSource = ({ dispatch, localName, password, sourceName, username }) => {
    if (typeof dispatch !== 'function') {
        dispatch = () => {};
    }

    return new Promise((resolve, reject) => {
        import('pouchdb-browser').then(async (module) => {
            const PouchDB = module.default;
            PouchDB.plugin(find);

            console.info(`${localName}: starting`);

            const localDB = await new PouchDB(localName);

            const productsUrl = distantDbPathBase + sourceName;
            const sourceDB = await new PouchDB(productsUrl, {
                auth: { password, username },
                skip_setup: true,
            });

            sourceDB.info().then((result) => {
                dispatch(
                    setTotalEntries({
                        db: localName,
                        totalEntries: result.doc_count,
                    }),
                );
            });

            return localDB.replicate
                .from(sourceDB)
                .on('change', (change) => {
                    if (change.pending > 0) {
                        dispatch(
                            setRemainingEntries({
                                db: localName,
                                nbEntries: change.pending,
                            }),
                        );
                    } else {
                        dispatch(
                            setRemainingEntries({
                                db: localName,
                                nbEntries: 0,
                            }),
                        );
                    }
                })
                .on('complete', async () => {
                    console.info(`${localName}: complete`);
                    resolve({ localDB, sourceDB });
                })
                .on('error', (error) => {
                    reject(error);
                });
        });
    });
};

export const initClientDBReplication = ({ name, password, username }) => {
    return new Promise((resolve, reject) => {
        if (window?.replicationHandler) {
            resolve(true);
            return;
        }

        import('pouchdb-browser').then(async (module) => {
            const PouchDB = module.default;

            const localDB = await new PouchDB('clientDB');

            const productsUrl = distantDbPathBase + name;
            const sourceDB = await new PouchDB(productsUrl, {
                auth: { password, username },
                skip_setup: true,
            });

            try {
                console.info('clientDB: init replicate live');
                window.replicationHandler = localDB.replicate
                    .to(sourceDB, { live: true, retry: true })
                    .on('change', (t) => console.info('change', t))
                    .on('error', (t) => console.error('error', t));

                resolve(true);
            } catch (error) {
                reject(error);
            }
        });
    });
};

export const clientDbSave = ({ name, password, username }) => {
    console.info('clientDbSave -> name', name);
    return new Promise((resolve, reject) => {
        import('pouchdb-browser').then((module) => {
            const PouchDB = module.default;

            const replicateDB = async () => {
                console.info('Client DB: start saving');
                const clientsUrl = distantDbPathBase + name;

                const localClientsDB = await new PouchDB('clientDB');
                const sourceClientsDB = await new PouchDB(clientsUrl, {
                    auth: { password, username },
                    skip_setup: true,
                });

                localClientsDB.replicate
                    .to(sourceClientsDB)
                    .on('complete', (infos) => {
                        console.info('replicateDB -> infos', infos);
                        console.info('Client DB: saved');
                        resolve(true);
                    })
                    .on('error', (error) => {
                        console.error('error', error);
                        reject(error);
                    });
            };
            replicateDB();
        });
    });
};

export const removeAllDb = () => {
    return new Promise((resolve, reject) => {
        import('pouchdb-browser').then((module) => {
            const PouchDB = module.default;

            const remove = async () => {
                console.info('Start removing DBs');
                const centralizedImages = await new PouchDB('centralizedImagesDB');
                const products = await new PouchDB('productDB');
                const kiosk = await new PouchDB('kioskDB');

                try {
                    console.info('Images DB: removing...');
                    await centralizedImages.destroy();
                    console.info('Images DB: OK Removed');
                    console.info('Products DB: removing...');
                    await products.destroy();
                    console.info('Products DB: OK Removed');
                    console.info('Kiosk DB: removing...');
                    await kiosk.destroy();
                    console.info('Kiosk DB: OK Removed');
                    resolve(true);
                } catch (error) {
                    reject(error);
                }
            };
            remove();
        });
    });
};
