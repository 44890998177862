export const DIAGNOSTIC_MOVEMENTS_PRODUCTS = [
    {
        img: 'deambulateur-BRIGHTON.jpg',
        name: 'Déambulateur',
    },
    {
        img: 'fauteuil-roulant-v500.jpeg',
        name: 'Fauteuils roulants',
    },
    {
        img: 'Handipharm_potence-sur-pied-xxl.jpg',
        name: 'Potence de lit',
    },
    {
        img: '4d2015d8-c655-4a9c-9bd5-521eaa7efd0e_3085_1_small.jpg',
        name: 'Table de lit',
    },
    {
        img: 'fauteuil-a-pousser.jpg',
        name: 'Fauteuils à pousser',
    },
    {
        img: 'identites-loupe-universelle-eclairante-819002-1.jpeg',
        name: 'Loupe',
    },
    {
        img: 'identites-paire-rampes-telescopiques-ergo.jpeg',
        name: 'Rampe',
    },
];

export const DIAGNOSTIC_HYGIENE_PRODUCTS = [
    {
        img: 'A0207922-01.png',
        name: 'Chaise pot',
    },
    {
        img: 'identites-urinal-anti-reflux-ursec.jpeg',
        name: 'Urinal',
    },
    {
        img: '480c2b1e-9b28-4e5d-a444-c493141378e1_870_1_small.jpg',
        name: 'Chaise de bain',
    },
    {
        img: '134056_40.jpg',
        name: 'Incontinence',
    },
    {
        img: '500420.jpg',
        name: 'Réhausse WC',
    },
    {
        img: 'gant-dr-wellega.jpeg',
        name: 'Gant de toilette',
    },
    {
        img: 'identites-alese-protege-matelas-forme-plateau.jpeg',
        name: 'Alèse',
    },
];

export const DIAGNOSTIC_DRESSING_PRODUCTS = [
    {
        img: '440a7986-b021-4de8-9438-fef229d57861_1815_1_small.jpg',
        name: 'Pince de préhension',
    },
    {
        img: 'chausse-pied-flamingo-4.jpeg',
        name: 'Chausse pied',
    },
    {
        img: 'enfile-bas-metal.jpg',
        name: 'Enfile bas',
    },
    {
        img: '5b3d2eb6-e52c-443d-b7fa-215c4287293d_228_1_small.jpg',
        name: 'Enfile bouton',
    },
    {
        img: 'aabb6e8273d83f1eea4527cf7f6ddb89b8c6b1a4_109672_retire_bas_samba.webp',
        name: 'Retire Bas',
    },
    {
        img: '71q5zHbsk8L._SX522_.jpg',
        name: 'Accroche soutien-gorge',
    },
    {
        img: 'fe0db6ba-e541-458d-b5a3-9c01c796e55b_4297_1_small.jpg',
        name: 'Enfile Chaussette',
    },
];

export const DIAGNOSTIC_FOOD_PRODUCTS = [
    {
        img: 'identites-ouvre-bouteille-dycem.jpeg',
        name: 'Ouvre bouteille',
    },
    {
        img: 'identites-couverts-torsogrip-2.jpeg',
        name: 'Couverts',
    },
    {
        img: 'tasse-handycup.jpeg',
        name: 'Tasse',
    },
    {
        img: 'identites-assiette-rebord-ventouse.jpeg',
        name: 'Assiette',
    },
    {
        img: 'A0007471.jpeg',
        name: 'Bavoir',
    },
    {
        img: 'verre-avec-decoupe-nasale.jpeg',
        name: 'Verre avec découpe',
    },
];

export const DIAGNOSTIC_RISKS_PRODUCTS = [
    {
        img: 'GPS_Localise.png',
        name: 'GPS Localise',
    },
    {
        img: 'BA0019-visu-2.jpg',
        name: 'Tapis anti-dérapant',
    },
    {
        img: '73c80782-fe00-4bb6-923a-b98aac0240e2_665_1_small.jpg',
        name: 'Barre de maintien',
    },

    {
        img: '1940429000-telephone-grandes-touches-personnalisables-lbs-medical-1_1.jpeg',
        name: 'Téléphone grandes touches',
    },
    {
        img: 'identites-barriere-lit-modulo-plus-3.jpeg',
        name: 'Barrière de lit',
    },
    {
        img: 'Handipharm_poignee-de-lit.jpg',
        name: 'Poignée sortie de lit',
    },
    {
        img: 'e9e5fe2f-926a-4c8f-bc9c-e178047d73e9_1836_1_small.jpg',
        name: 'Poignée sortie de bain',
    },
];

export const DIAGNOSTIC_MEDICAL_CARE_PRODUCTS = [
    {
        img: 'identites-pilbox-cardio-2.jpeg',
        name: 'Pilulier',
    },
    {
        img: '8279421-1-3401582794214-alcura-separateur-comprime.jpg',
        name: 'Séparateur comprimé',
    },
    {
        img: 'A0203670.jpeg',
        name: 'Ecrase comprimé',
    },
    {
        img: 'oxymetre-de-pouls-doigt.jpg',
        name: 'Oxymètre de pouls',
    },
    {
        img: 'pilulier-mensuel.jpeg',
        name: 'Pilulier mensuel',
    },
    {
        img: 'aide-verseur-de-gouttes.jpg',
        name: 'Aide verseur',
    },
];
