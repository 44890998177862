const isBrowser = typeof window !== `undefined`;

const getAdmin = () => (window.localStorage.gatsbyAdmin ? JSON.parse(window.localStorage.gatsbyAdmin) : {});

const setAdmin = (admin) => (window.localStorage.gatsbyAdmin = JSON.stringify(admin));

export const handleLoginAdmin = async ({ login, password }) => {
    return new Promise((resolve, reject) => {
        if (!isBrowser) return false;

        if (login === `medikiosk` && password === `mpmedikiosk`) {
            console.info(`Credentials match! Setting the active admin.`);
            const admin = {
                loggedIn: true,
                login: `medikiosk`,
            };
            setAdmin(admin);
            resolve(admin);
        }

        const error = {
            response: { status: 401 },
        };

        reject(error);
    });
};

export const isLoggedInAdmin = () => {
    if (!isBrowser) return false;

    const admin = getAdmin();

    return !!admin.loggedIn;
};

export const getCurrentAdmin = () => isBrowser && getAdmin();

export const logout = () => {
    if (!isBrowser) return;

    console.info(`Ensuring the \`gatsbyAdmin\` property exists.`);
    setAdmin({});
};
