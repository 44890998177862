import { formatAnswersSkeleton } from '../utils';
import {
    DIAGNOSTIC_MOVEMENTS_TEXT_RESULTS,
    DIAGNOSTIC_HYGIENE_TEXT_RESULTS,
    DIAGNOSTIC_DRESSING_TEXT_RESULTS,
    DIAGNOSTIC_FOOD_TEXT_RESULTS,
    DIAGNOSTIC_RISKS_TEXT_RESULTS,
    DIAGNOSTIC_MEDICAL_CARE_TEXT_RESULTS,
} from './categoriesTextResults';
import {
    alimentationCropped,
    deplacementCropped,
    habillageCropped,
    hygieneCropped,
    risqueCropped,
    traitementCropped,
    alimentation,
    deplacement,
    habillage,
    hygiene,
    risque,
    traitement,
} from './categoryImages';
import {
    DIAGNOSTIC_DRESSING_PRODUCTS,
    DIAGNOSTIC_FOOD_PRODUCTS,
    DIAGNOSTIC_HYGIENE_PRODUCTS,
    DIAGNOSTIC_MEDICAL_CARE_PRODUCTS,
    DIAGNOSTIC_MOVEMENTS_PRODUCTS,
    DIAGNOSTIC_RISKS_PRODUCTS,
} from './products';
import {
    DIAGNOSTIC_SUBCATEGORIES_MOVEMENTS,
    DIAGNOSTIC_SUBCATEGORIES_HYGENE,
    DIAGNOSTIC_SUBCATEGORIES_DRESSING,
    DIAGNOSTIC_SUBCATEGORIES_FOOD,
    DIAGNOSTIC_SUBCATEGORIES_RISKS,
    DIAGNOSTIC_SUBCATEGORIES_MEDICAL_CARE,
} from './subcategories';

const DIAGNOSTIC_CATEGORY_MOVEMENTS = 'Déplacements et activités';
const DIAGNOSTIC_CATEGORY_HYGENE = 'Hygiène';
const DIAGNOSTIC_CATEGORY_DRESSING = 'Habillage';
const DIAGNOSTIC_CATEGORY_FOOD = 'Alimentation';
const DIAGNOSTIC_CATEGORY_RISKS = 'Gestion des risques';
const DIAGNOSTIC_CATEGORY_MEDICAL_CARE = 'Suivi des traitements';

export const diagnosticCategories = [
    {
        icon: 'deplacement',
        id: 1,
        image: deplacement,
        imageCropped: deplacementCropped,
        label: DIAGNOSTIC_CATEGORY_MOVEMENTS,
        products: DIAGNOSTIC_MOVEMENTS_PRODUCTS,
        subCategories: DIAGNOSTIC_SUBCATEGORIES_MOVEMENTS,
        textResults: DIAGNOSTIC_MOVEMENTS_TEXT_RESULTS,
    },
    {
        icon: 'hygiene',
        id: 2,
        image: hygiene,
        imageCropped: hygieneCropped,
        label: DIAGNOSTIC_CATEGORY_HYGENE,
        products: DIAGNOSTIC_HYGIENE_PRODUCTS,
        subCategories: DIAGNOSTIC_SUBCATEGORIES_HYGENE,
        textResults: DIAGNOSTIC_HYGIENE_TEXT_RESULTS,
    },
    {
        icon: 'habillage',
        id: 3,
        image: habillage,
        imageCropped: habillageCropped,
        label: DIAGNOSTIC_CATEGORY_DRESSING,
        products: DIAGNOSTIC_DRESSING_PRODUCTS,
        subCategories: DIAGNOSTIC_SUBCATEGORIES_DRESSING,
        textResults: DIAGNOSTIC_DRESSING_TEXT_RESULTS,
    },
    {
        icon: 'alimentation',
        id: 4,
        image: alimentation,
        imageCropped: alimentationCropped,
        label: DIAGNOSTIC_CATEGORY_FOOD,
        products: DIAGNOSTIC_FOOD_PRODUCTS,
        subCategories: DIAGNOSTIC_SUBCATEGORIES_FOOD,
        textResults: DIAGNOSTIC_FOOD_TEXT_RESULTS,
    },
    {
        icon: 'risque',
        id: 5,
        image: risque,
        imageCropped: risqueCropped,
        label: DIAGNOSTIC_CATEGORY_RISKS,
        products: DIAGNOSTIC_RISKS_PRODUCTS,
        subCategories: DIAGNOSTIC_SUBCATEGORIES_RISKS,
        textResults: DIAGNOSTIC_RISKS_TEXT_RESULTS,
    },
    {
        icon: 'traitement',
        id: 6,
        image: traitement,
        imageCropped: traitementCropped,
        label: DIAGNOSTIC_CATEGORY_MEDICAL_CARE,
        products: DIAGNOSTIC_MEDICAL_CARE_PRODUCTS,
        subCategories: DIAGNOSTIC_SUBCATEGORIES_MEDICAL_CARE,
        textResults: DIAGNOSTIC_MEDICAL_CARE_TEXT_RESULTS,
    },
];

export const DIAGNOSTIC_ANSWERS_SKELETON = formatAnswersSkeleton(diagnosticCategories);
