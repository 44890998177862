export const DIAGNOSTIC_MOVEMENTS_TEXT_RESULTS = {
    a: {
        globalTexts: [
            'Vous êtes libre de vos mouvements et l’enjeu est de préserver votre qualité de vie. Il sera donc important de veiller à votre sécurité et votre confort.',
            "L’aménagement et l’équipement de votre chambre, de votre salle de bain ou de votre salon, par exemple, est essentiel pour préserver confort et sécurité. D'autres produits pourront par ailleurs soulager votre quotidien et prévenir d'éventuels accidents.",
        ],
        sections: [
            {
                texts: [
                    'Pour limiter les risques de chutes, il est important de disposer d’un éclairage adapté permettant de sécuriser vos déplacements lorsqu’il fait sombre et pendant la nuit. Pour prévenir de ces risques,vous pouvez dégager les lieux de passage de tout objet qui pourrait représenter un risque. Fils électriques, tapis glissants… Les objets obstruants, les lieux de passage représentent autant de risques de chutes !',
                ],
                title: 'Dans votre chambre',
            },
            {
                texts: [
                    'Nous proposons de nombreux fauteuils de repos ou fauteuils releveurs électriques, leur confort et leur esthétisme permettent de passer d’agréables moments dans votre salon.',
                ],
                title: 'Dans votre salon',
            },
            {
                texts: [
                    'Votre niveau d’autonomie ne requiert pas l’utilisation d’un matériel d’aide à la marche. Néanmoins, si vous êtes âgé, avoir une canne de marche à votre disposition vous permet d’anticiper d’éventuelles fatigues et perte d’équilibre liées à l’âge. Si vous appréhendez une chute...',
                    'Pour la marche, privilégiez le port de chaussures confortables et adaptées telles que les chaussures orthopédiques.',
                    'Avoir quelques accessoires de confort vous permet également de prévenir le mal de dos ainsi que les jambes lourdes lors des longs trajets en transport ( voiture, train, avion) : coussin, bas de contention...',
                ],
                title: 'A l’extérieur',
            },
            {
                texts: [
                    "L'allumage automatique permet de sécuriser davantage les couloirs ou pièces sombres, il est économique et ergonomique puisqu’il ne nécessite pas d’utiliser d’interrupteur.",
                ],
                title: 'A l’intérieur',
            },
        ],
    },
    b: {
        conclusion: [
            'Si votre équilibre devient plus précaire, vous devez absolument sécuriser vos déplacements pour prévenir le risque de chute. De très nombreuses aides à la marches existent. Afin de faire le bon choix, nous vous recommandons de demander conseil à votre pharmacien.',
        ],
        globalTexts: [
            'Vous rencontrez des difficultés à réaliser les gestes du quotidien et votre mobilité s’en trouve réduite. L’enjeu est de préserver votre qualité de vie et de faciliter vos déplacements.',
            'Il sera donc important de veiller à votre sécurité et votre confort.',
            "L’aménagement de votre chambre, de votre salle de bain ou de votre salon doit être repensé lorsque les gestes de la vie quotidienne deviennent plus difficiles et ainsi limiter les risques d'accident domestique. Des solutions existent également pour appréhender au mieux vos déplacements à l'extérieur et vos activités, le suivi de vos traitements ou bien la prise des repas. ",
        ],
        sections: [
            {
                texts: [
                    'Un lit à hauteur variable peut être envisagé. Il vous permet de baisser et monter le lit à votre convenance pour faciliter le lever et le coucher. Des barres d’accès peuvent favoriser l’équilibre. Vous pouvez également opter pour un déambulateur fixe qui vous donnera une stabilité supplémentaire pour sécuriser la descente du lit.',
                ],
                title: 'Dans votre chambre',
            },
            {
                texts: [
                    'Vous devez privilégier votre confort et votre sécurité. L’installation d’un fauteuil avec une assistance au lever favorisera le confort et encouragera l’activité physique, indispensable au maintien de l’autonomie.',

                    'Si votre équilibre devient plus précaire, vous devez absolument sécuriser vos déplacements pour prévenir le risque de chute. De très nombreuses aides à la marches existent. Afin de faire le bon choix, nous vous recommandons de demander conseil à votre pharmacien.',
                ],
                title: 'Dans votre salon',
            },
            {
                texts: [
                    'Pour pouvoir vous déplacer, vous avez besoin de matériels d’aide à la mobilité. Avec une autonomie fortement réduite, optez pour un déambulateur (nombre de roues en fonction de votre capacité à marcher) lors de vos déplacements au sein du domicile et lors des petites balades et courts trajets en extérieur. Pour les longs trajets, un fauteuil roulant sera indispensable.',
                ],
                title: 'A l’extérieur',
            },
        ],
    },
    c: {
        globalTexts: [
            'Vous avez besoin d’une aide soutenue pour réaliser les gestes du quotidien et vous devez faire appel à une tierce personne pour vos déplacements.',

            'Il sera donc particulièrement important de veiller à votre sécurité et votre confort.',

            "L’aménagement de l'ensemble des pièces de votre domicile est essentiel pour maintenir votre autonomie. ll est important de s’équiper d’aides techniques pour faciliter votre quotidien ainsi que celui de votre aidant.",

            'L’auxiliaire de vie, peut vous accompagner jour et nuit. Une aide au coucher peut être mise en place afin de vous préparer et aider au coucher, mais aussi intervenir au cours de la nuit pour garantir votre sécurité. Egalement utile pour vous aider à effectuer ces tâches difficiles comme s’habiller et se laver, tout en vous laissant un maximum d’autonomie. Enfin elle vous aidera à garder une alimentation saine en préparant vos repas, mais aussi vous aide à vous alimenter en cas de perte d’autonomie sévère. L’aide soignant peut aussi vous aider lors de la prise de vos médicaments.',

            'Votre pharmacien est là pour vous accompagner dans ces démarches. ',
        ],
        sections: [
            {
                texts: [
                    'L’installation d’un lit médicalisé et l’achat d’un matelas de prévention des escarres sont fortement recommandés pour vous soulager et améliorer votre confort au quotidien. Il favorise le travail des aidants.',
                ],
                title: 'Dans votre chambre',
            },
            {
                texts: [
                    'Nous proposons des dispositifs pensés et conçus spécifiquement pour les patients en perte d’autonomie, restant de longs moments au fauteuil. Les fauteuils de repos, fauteuils releveurs électriques, fauteuils à pousser ou sièges coquilles favoriseront le maintien et le confort tout autant que la sécurité de mouvement. Les déplacements doivent aussi être sécurisés.',
                ],
                title: 'Dans votre salon',
            },
            {
                texts: [
                    'Pour pouvoir vous déplacer, vous avez besoin de matériels d’aide à la mobilité. Avec une autonomie fortement réduite, optez pour un déambulateur (nombre de roues en fonction de votre capacité à marcher) lors de vos déplacements au sein du domicile et lors des petites balades et courts trajets en extérieur. Pour les longs trajets, un fauteuil roulant sera indispensable.',
                ],
                title: 'A l’extérieur',
            },
            {
                texts: [
                    'Si vous ne pouvez plus vous mettre en position debout, le fauteuil roulant sera nécessaire même au sein de votre domicile. Pour cela, il faudra adapter votre logement. Un ergothérapeute peut vous accompagner.',
                ],
                title: 'A l’intérieur',
            },
        ],
    },
};

export const DIAGNOSTIC_HYGIENE_TEXT_RESULTS = {
    a: {
        globalTexts: [
            'Vous êtes libre de vos mouvements et l’enjeu est de préserver votre qualité de vie. Il sera donc important de veiller à votre sécurité et votre confort.',

            "L’aménagement et l’équipement de votre chambre, de votre salle de bain ou de votre salon, par exemple, est essentiel pour préserver confort et sécurité. D'autres produits pourront par ailleurs soulager votre quotidien et prévenir d'éventuels accidents.",
        ],
        sections: [
            {
                texts: [
                    'Pour préserver son autonomie intacte le plus longtemps possible il convient de limiter tous les risques',

                    'La salle de bain est l’une des pièces les plus accidentogène de la maison. Sols humides ou mouillés sont à l’origine de dangereuses glissades aux conséquences parfois dramatiques. La chute est la principale cause de la perte d’autonomie, il convient donc de prendre très au sérieux l’aménagement de sa salle de bain pour garantir le plus haut niveau de sécurité.',

                    'Parmis les nombreux équipements, il y a quelques incontournables : les tapis anti glisse et marche-pieds qui sont efficaces pour sécuriser la salle de bain.',
                ],
                title: 'Dans votre salle de bain',
            },
            {
                texts: [
                    'Pour le moment l’autonomie reste suffisante pour un usage des toilettes en toute autonomie.',

                    'On peut néanmoins anticiper et installer une barre d’appui qui pourra être une aide ponctuelle au début puis plus régulière au fil du temps. Si un projet de réaménagement est à l’étude, il faudra veiller à anticiper l’apparition probable d’une perte d’autonomie avec l’avancée en âge. L’installation de WC plus élevé facilitera le passage de la position assise à la position levée.',
                ],
                title: 'WC',
            },
        ],
    },
    b: {
        globalTexts: [
            'Vous rencontrez des difficultés à réaliser les gestes du quotidien et votre mobilité s’en trouve réduite. L’enjeu est de préserver votre qualité de vie et de faciliter vos déplacements.',

            'Il sera donc important de veiller à votre sécurité et votre confort.',

            "L’aménagement de votre chambre, de votre salle de bain ou de votre salon doit être repensé lorsque les gestes de la vie quotidienne deviennent plus difficiles et ainsi limiter les risques d'accident domestique. Des solutions existent également pour appréhender au mieux vos déplacements à l'extérieur et vos activités, le suivi de vos traitements ou bien la prise des repas.",
        ],
        sections: [
            {
                texts: [
                    "L'aménagement de votre salle de bain est une priorité car c’est la pièce de tous les dangers en matière de chutes.",
                    'La salle de bain est l’une des pièces les plus accidentogène de la maison. Sols humides ou mouillés sont à l’origine de dangereuses glissades aux conséquences parfois dramatiques. La chute est la principale cause de la perte d’autonomie, il convient donc de prendre très au sérieux l’aménagement de la salle de bain pour garantir le plus haut niveau de sécurité.',
                    'Au delà des produits et équipements incontournables (tapis anti-dérapant, marche-pieds, …) il va peut être devenir utile de compléter l’aménagement de votre salle de bain : barre de maintien pour remédier à un déficit de l’équilibre, tabouret pour la douche ou planche pour le bain sont autant d’accessoires qui contribueront à vous garder en sécurité le plus longtemps possible.',
                    'Ne pas oublier non plus de découvrir les accessoires qui facilitent le quotidien : une brosse à long manche pour la toilette du dos ou les accessoires d’aide à l’habillage seront des alliés précieux qui contribueront à rendre la toilette plus agréable.',
                ],
                title: 'Dans votre salle de bain',
            },
            {
                texts: [
                    "L'intégration de nouveaux aménagements ou de nouveaux WC adaptés permet de s’affranchir de toute aide extérieure et de conserver, le plus longtemps possible son autonomie et son intimité.",
                    'Les difficultés de changement de postures (assis / debout) ainsi que la perte progressive de l’équilibre sont les deux principaux facteurs à prendre en compte dans l’aménagement.',
                    'Le recours à un surélévateur de toilette et l’installation d’une barre de maintien répondent généralement bien à ces problématiques. Notre guide d’achat “comment aménager ses toilettes” vous aidera à identifier la solution la mieux adaptée au regard de la configuration de vos WC et des déficits à compenser.',
                ],
                title: 'WC',
            },
        ],
    },
    c: {
        globalTexts: [
            'Vous avez besoin d’une aide soutenue pour réaliser les gestes du quotidien et vous devez faire appel à une tierce personne pour vos déplacements.',

            'Il sera donc particulièrement important de veiller à votre sécurité et votre confort.',

            "L’aménagement de l'ensemble des pièces de votre domicile est essentiel pour maintenir votre autonomie. ll est important de s’équiper d’aides techniques pour faciliter votre quotidien ainsi que celui de votre aidant.",

            'L’auxiliaire de vie, peut vous accompagner jour et nuit. Une aide au coucher peut être mise en place afin de vous préparer et aider au coucher, mais aussi intervenir au cours de la nuit pour garantir votre sécurité. Egalement utile pour vous aider à effectuer ces tâches difficiles comme s’habiller et se laver, tout en vous laissant un maximum d’autonomie. Enfin elle vous aidera à garder une alimentation saine en préparant vos repas, mais aussi vous aide à vous alimenter en cas de perte d’autonomie sévère. L’aide soignant peut aussi vous aider lors de la prise de vos médicaments.',

            'Votre pharmacien est là pour vous accompagner dans ces démarches.',
        ],
        sections: [
            {
                texts: [
                    'Vous devez d’abord vous assurer que l’usage de cette pièce reste possible. Dans le cas contraire, les toilettes devront s’effectuer dans une pièce plus adaptée, voire au lit lorsque tout déplacement devient inenvisageable.',
                    'Si l’accès est encore possible, vous devez vous assurer que l’usage de la baignoire ou de la douche peut s’effectuer dans les meilleures conditions de confort et de sécurité.',
                    'Par exemple, la baignoire pourra être équipée d’une barre d’accès et d’un fauteuil de bain pivotant qui garantiront un bon maintien lors de la toilette et le maximum de facilité pour rentrer et sortir de la baignoire.',
                    'Dans la douche, il faudra probablement étudier l’installation d’un tabouret de douche ce qui évitera une de rester debout trop longtemps. Pour l’aidant c’est aussi un moyen sécurisant pour accompagner ou assister le patient lors de sa toilette',
                ],
                title: 'Salle de bain',
            },
            {
                texts: [
                    'Dans la mesure du possible et le plus longtemps possible, il faut privilégier la mobilisation au déplacement pour favoriser au maximum l’exercice physique. Lorsqu’un accompagnement par un tiers pour se rendre aux toilettes n’est pas possible, on cherchera des solutions adaptées : une chaise pot (appelée “chaise garde robe”) placée à proximité du lit est une bonne solution si l’on peut encore y accéder. Dans le cas échéant, il faudra envisager l’utilisation de protections (couches et alèses de protection de la literie) ou de dispositifs alternatifs (urinal par exemple).',
                ],
                title: 'WC',
            },
        ],
    },
};

export const DIAGNOSTIC_DRESSING_TEXT_RESULTS = {
    a: {
        globalTexts: [
            'Vous êtes libre de vos mouvements et l’enjeu est de préserver votre qualité de vie. Il sera donc important de veiller à votre sécurité et votre confort.',
            "L’aménagement et l’équipement de votre chambre, de votre salle de bain ou de votre salon, par exemple, est essentiel pour préserver confort et sécurité. D'autres produits pourront par ailleurs soulager votre quotidien et prévenir d'éventuels accidents.",
        ],
        sections: [
            {
                texts: [
                    'Vous avez déjà procédé à la sécuristation de vos principaux lieux de vie, notamment de votre chambre. Votre autonomie est bonne mais vous pouvez améliorer votre quotidien en facilitant les gestes "difficiles" : attraper un objet au sol, boutonner une chemise, enfiler des bas. Nous avons de nombreuses solutions pouvant vous satisfaire.',
                ],
                title: '',
            },
        ],
    },
    b: {
        globalTexts: [
            'Vous rencontrez des difficultés à réaliser les gestes du quotidien et votre mobilité s’en trouve réduite. L’enjeu est de préserver votre qualité de vie et de faciliter vos déplacements.',

            'Il sera donc important de veiller à votre sécurité et votre confort.',

            "L’aménagement de votre chambre, de votre salle de bain ou de votre salon doit être repensé lorsque les gestes de la vie quotidienne deviennent plus difficiles et ainsi limiter les risques d'accident domestique. Des solutions existent également pour appréhender au mieux vos déplacements à l'extérieur et vos activités, le suivi de vos traitements ou bien la prise des repas.",
        ],
        sections: [
            {
                texts: [
                    'Vous rencontrez des difficultés à réaliser les gestes du quotidien et votre mobilité s’en trouve réduite. L’enjeu est de préserver votre qualité de vie et de faciliter vos déplacements. Vous pouvez également améliorer votre quotidien en facilitant les gestes "difficiles" : attraper un objet au sol, boutonner une chemise, enfiler des bas. Nous avons de nombreuses solutions pouvant vous satisfaire. Dans certains cas il peut être nécessaire de faire intervenir un aidant. Votre pharmacien est là pour vous conseiller.',
                ],
                title: '',
            },
        ],
    },
    c: {
        globalTexts: [
            'Vous avez besoin d’une aide soutenue pour réaliser les gestes du quotidien et vous devez faire appel à une tierce personne pour vos déplacements.',

            'Il sera donc particulièrement important de veiller à votre sécurité et votre confort.',

            "L’aménagement de l'ensemble des pièces de votre domicile est essentiel pour maintenir votre autonomie. ll est important de s’équiper d’aides techniques pour faciliter votre quotidien ainsi que celui de votre aidant.",

            'L’auxiliaire de vie, peut vous accompagner jour et nuit. Une aide au coucher peut être mise en place afin de vous préparer et aider au coucher, mais aussi intervenir au cours de la nuit pour garantir votre sécurité. Egalement utile pour vous aider à effectuer ces tâches difficiles comme s’habiller et se laver, tout en vous laissant un maximum d’autonomie. Enfin elle vous aidera à garder une alimentation saine en préparant vos repas, mais aussi vous aide à vous alimenter en cas de perte d’autonomie sévère. L’aide soignant peut aussi vous aider lors de la prise de vos médicaments.',

            'Votre pharmacien est là pour vous accompagner dans ces démarches.',
        ],
        sections: [
            {
                texts: [
                    `Vous rencontrez des difficultés à réaliser les gestes du quotidien et votre mobilité s’en trouve réduite. L’enjeu est de préserver votre qualité de vie et de faciliter vos déplacements. Vous pouvez également améliorer votre quotidien en facilitant les gestes "difficiles" : attraper un objet au sol, boutonner une chemise, enfiler des bas. Nous avons de nombreuses solutions pouvant vous satisfaire. Dans certains cas il peut être nécessaire de faire intervenir un aidant. Votre pharmacien est là pour vous conseiller.`,
                ],
                title: '',
            },
        ],
    },
};

export const DIAGNOSTIC_FOOD_TEXT_RESULTS = {
    a: {
        globalTexts: [
            'Vous êtes libre de vos mouvements et l’enjeu est de préserver votre qualité de vie. Il sera donc important de veiller à votre sécurité et votre confort.',
            "L’aménagement et l’équipement de votre chambre, de votre salle de bain ou de votre salon, par exemple, est essentiel pour préserver confort et sécurité. D'autres produits pourront par ailleurs soulager votre quotidien et prévenir d'éventuels accidents.",
        ],
        sections: [
            {
                texts: [
                    'Même si votre autonomie reste suffisante, certains de vos gestes quotidiens sont difficiles à exercer.',

                    'Pour limiter les risques de chute, évitez les sièges pivotants et optez plutôt pour des solutions innovantes comme des chaises haute de cuisine. Ces chaises sont légèrement inclinées pour permettre de s’asseoir et se relever en un minimum d’effort.',

                    'Il est préférable de réorganiser les placards et de limiter les objets en hauteur. Rangez à portée de main les ustensiles que vous utilisez fréquemment. Nous vous conseillons d’installer un plan de travail ergonomique à votre hauteur et bien éclairé.',

                    'Malgré un bon niveau d’autonomie, vos performances sensorielles peuvent être altérées. Pour éviter de vous blesser, vous pouvez trouver dans notre guide conseil “aménager la cuisine” une liste de nos divers accessoires pour vous facilitez les gestes du quotidien. Par exemple, un ouvre-bocal conique et souple pour réduire considérablement l’effort pour ouvrir les bocaux ou bouchons.',

                    'Pour prévenir les risques de mal de dos, nous mettons à votre disposition une gamme de produits afin d’entretenir votre maison: ramasse poussière avec long manche, poignées de porte ergonomiques.',
                ],
                title: '',
            },
        ],
    },
    b: {
        globalTexts: [
            'Vous rencontrez des difficultés à réaliser les gestes du quotidien et votre mobilité s’en trouve réduite. L’enjeu est de préserver votre qualité de vie et de faciliter vos déplacements.',

            'Il sera donc important de veiller à votre sécurité et votre confort.',

            "L’aménagement de votre chambre, de votre salle de bain ou de votre salon doit être repensé lorsque les gestes de la vie quotidienne deviennent plus difficiles et ainsi limiter les risques d'accident domestique. Des solutions existent également pour appréhender au mieux vos déplacements à l'extérieur et vos activités, le suivi de vos traitements ou bien la prise des repas.",
        ],
        sections: [
            {
                texts: [
                    'Il est important de vous sentir bien chez vous et en sécurité. L’aménagement de votre cuisine, mais aussi l’utilisation d’un équipement adapté vous permet d’améliorer votre autonomie ainsi que la prise de vos repas.',
                    'L’autonomie des patients facilite aussi le quotidien des aidants.',
                    'Si vous souffrez de préhension ou de faiblesse dans le poignet, notre gamme de couteaux à lame courbée ou couverts à large poignée, permettent de ne pas vous sentir diminué davantage.',
                    'Les pinces de préhension facilitent aussi la préhension des objets métalliques qui permettent de compenser les déficits d’amplitude dans les mouvements.',
                    'Le verre tronqué avec découpe nasale permet de boire tout en gardant la tête droite pour éviter les risques d’étouffement. Les couvercles avec bec verseur vous permettent de boire à votre rythme grâce aux différents débits régulés.',
                    'Nous proposons par exemple l’assiette profilée Manoy pour les personnes ne se servant que d’une seule main. Avec le couteau fourchette pliant, qui présente deux parties utilisables sur le même couvert, vous pourrez manger que d’une seule main.',
                    'Le tapis ’antidérapant empêche les ustensiles de bouger, ce qui facilite la saisie de l’objet si vous souffrez de difficulté de préhension.',
                    'Avec le pilulier, vous pouvez couper ainsi que broyer en poudre vos médicaments.',
                ],
                title: 'Prendre son repas',
            },
        ],
    },
    c: {
        globalTexts: [
            'Vous avez besoin d’une aide soutenue pour réaliser les gestes du quotidien et vous devez faire appel à une tierce personne pour vos déplacements.',

            'Il sera donc particulièrement important de veiller à votre sécurité et votre confort.',

            "L’aménagement de l'ensemble des pièces de votre domicile est essentiel pour maintenir votre autonomie. ll est important de s’équiper d’aides techniques pour faciliter votre quotidien ainsi que celui de votre aidant.",

            'L’auxiliaire de vie, peut vous accompagner jour et nuit. Une aide au coucher peut être mise en place afin de vous préparer et aider au coucher, mais aussi intervenir au cours de la nuit pour garantir votre sécurité. Egalement utile pour vous aider à effectuer ces tâches difficiles comme s’habiller et se laver, tout en vous laissant un maximum d’autonomie. Enfin elle vous aidera à garder une alimentation saine en préparant vos repas, mais aussi vous aide à vous alimenter en cas de perte d’autonomie sévère. L’aide soignant peut aussi vous aider lors de la prise de vos médicaments.',

            'Votre pharmacien est là pour vous accompagner dans ces démarches.',
        ],
        sections: [
            {
                texts: [
                    'Votre autonomie est fortement réduite, mais des solutions ont été élaborées afin d’améliorer votre vie quotidienne.',
                    'La perte d’autonomie affecte la capacité à réaliser les gestes quotidiens. Nous vous proposons des solutions pour faciliter la préparation et la prise de repas. Nos solutions aident à pallier les déficiences qui se manifestent à toutes les étapes : préparer le repas, couper les aliments, s’en saisir, les porter à la bouche en tenant les couverts d’une façon stable et les ingérer.',
                ],
                title: 'Prendre son repas',
            },
            {
                texts: ['Si votre capacité de respiration est limitée, privilégiez des pailles anti reflux.'],
                title: 'Patient',
            },
            {
                texts: [
                    'Nous proposons aussi des solutions facilitant le quotidien de vos aidants. Le masticateur est une aide pour la tierce personne, dans la mesure où, il sert à préparer les aliments à des personnes incapables de mastiquer.',
                ],
                title: 'Aidant',
            },
        ],
    },
};

export const DIAGNOSTIC_RISKS_TEXT_RESULTS = {
    a: {
        globalTexts: [
            'Vous êtes libre de vos mouvements et l’enjeu est de préserver votre qualité de vie. Il sera donc important de veiller à votre sécurité et votre confort.',
            "L’aménagement et l’équipement de votre chambre, de votre salle de bain ou de votre salon, par exemple, est essentiel pour préserver confort et sécurité. D'autres produits pourront par ailleurs soulager votre quotidien et prévenir d'éventuels accidents.",
        ],
        sections: [
            {
                texts: [
                    'Vous appréhendez bien votre quotidien et maitrisez les risques inhérents à ce dernier. Cependant il est toujours préférable de prévenir. De nombreux produits pourront répondre à cet objectif et vous permettre de profiter plus sereinement de votre domicile et de vos déplacements.',
                ],
                title: '',
            },
        ],
    },
    b: {
        globalTexts: [
            'Vous rencontrez des difficultés à réaliser les gestes du quotidien et votre mobilité s’en trouve réduite. L’enjeu est de préserver votre qualité de vie et de faciliter vos déplacements.',

            'Il sera donc important de veiller à votre sécurité et votre confort.',

            "L’aménagement de votre chambre, de votre salle de bain ou de votre salon doit être repensé lorsque les gestes de la vie quotidienne deviennent plus difficiles et ainsi limiter les risques d'accident domestique. Des solutions existent également pour appréhender au mieux vos déplacements à l'extérieur et vos activités, le suivi de vos traitements ou bien la prise des repas.",
        ],
        sections: [
            {
                texts: [
                    'Une perte d’autonomie affecte les gestes de la vie quotidienne. Les auxiliaires de vie peuvent intervenir pour vous aider à effectuer ces tâches difficiles comme s’habiller et se laver, tout en vous laissant un maximum d’autonomie.',

                    'Cependant, une multitude d’aides techniques ont été développées pour compenser la perte d’autonomie et l’aide à la sécurité :',

                    'Les téléphones spécifiques (grandes touches, dotés d’une touche d’appel d’urgence, de numéros pré-enregistrés), la téléalarme (alerter une personne choisie au préalable) et les alarmes incendie spécifiques (lumière stroboscopique) sont autant de solutions pouvant sécuriser votre quotidien. Demandez conseil à votre pharmacien !',
                ],
                title: '',
            },
        ],
    },
    c: {
        globalTexts: [
            'Vous avez besoin d’une aide soutenue pour réaliser les gestes du quotidien et vous devez faire appel à une tierce personne pour vos déplacements.',

            'Il sera donc particulièrement important de veiller à votre sécurité et votre confort.',

            "L’aménagement de l'ensemble des pièces de votre domicile est essentiel pour maintenir votre autonomie. ll est important de s’équiper d’aides techniques pour faciliter votre quotidien ainsi que celui de votre aidant.",

            'L’auxiliaire de vie, peut vous accompagner jour et nuit. Une aide au coucher peut être mise en place afin de vous préparer et aider au coucher, mais aussi intervenir au cours de la nuit pour garantir votre sécurité. Egalement utile pour vous aider à effectuer ces tâches difficiles comme s’habiller et se laver, tout en vous laissant un maximum d’autonomie. Enfin elle vous aidera à garder une alimentation saine en préparant vos repas, mais aussi vous aide à vous alimenter en cas de perte d’autonomie sévère. L’aide soignant peut aussi vous aider lors de la prise de vos médicaments.',

            'Votre pharmacien est là pour vous accompagner dans ces démarches.',
        ],
        sections: [
            {
                texts: [
                    'Une perte d’autonomie affecte les gestes de la vie quotidienne. Les auxiliaires de vie peuvent intervenir pour vous aider à effectuer ces tâches difficiles comme s’habiller et se laver, tout en vous laissant un maximum d’autonomie. Cependant, une multitude d’aides techniques ont été développées pour compenser la perte d’autonomie et l’aide à la sécurité : Les téléphones spécifiques (grandes touches, dotés d’une touche d’appel d’urgence, de numéros pré-enregistrés), la téléalarme (alerter une personne choisie au préalable) et les alarmes incendie spécifiques (lumière stroboscopique) sont autant de solutions pouvant sécuriser votre quotidien. Demandez conseil à votre pharmacien !',
                ],
                title: '',
            },
        ],
    },
};

export const DIAGNOSTIC_MEDICAL_CARE_TEXT_RESULTS = {
    a: {
        globalTexts: [
            'Vous êtes libre de vos mouvements et l’enjeu est de préserver votre qualité de vie. Il sera donc important de veiller à votre sécurité et votre confort.',
            "L’aménagement et l’équipement de votre chambre, de votre salle de bain ou de votre salon, par exemple, est essentiel pour préserver confort et sécurité. D'autres produits pourront par ailleurs soulager votre quotidien et prévenir d'éventuels accidents.",
        ],
        sections: [
            {
                texts: [
                    'Vous avez un bon niveau d’autonomie et vous souhaitez prendre soin de vous pour rester en bonne santé.',
                ],
                title: '',
            },
            {
                texts: [
                    'Nous vous proposons aussi des solutions pour vous informer de votre état physique et de vous permettre de faire un autodiagnostic.',
                ],
                title: '',
            },
            {
                texts: [
                    'Nous vous proposons des solutions d’autodiagnostic tels que les tensiomètres et les oxymètres: tensiomètre de bras, tensiomètre de poignet et oxymètre de pouls pour mesurer avec précision la saturation artérielle en hémoglobine.',
                ],
                title: '',
            },
        ],
    },
    b: {
        globalTexts: [
            'Vous rencontrez des difficultés à réaliser les gestes du quotidien et votre mobilité s’en trouve réduite. L’enjeu est de préserver votre qualité de vie et de faciliter vos déplacements.',

            'Il sera donc important de veiller à votre sécurité et votre confort.',

            "L’aménagement de votre chambre, de votre salle de bain ou de votre salon doit être repensé lorsque les gestes de la vie quotidienne deviennent plus difficiles et ainsi limiter les risques d'accident domestique. Des solutions existent également pour appréhender au mieux vos déplacements à l'extérieur et vos activités, le suivi de vos traitements ou bien la prise des repas.",
        ],
        sections: [
            {
                texts: [
                    "Le suivi de traitement est un sujet très important notamment dans le cas d'une perte partielle d'autonomie. Avec le pilulier, vous pouvez couper ainsi que broyer en poudre vos médicaments. Nous vous proposons également des solutions d’autodiagnostic tels que les tensiomètres et les oxymètres: tensiomètre de bras, tensiomètre de poignet et oxymètre de pouls pour mesurer avec précision la saturation artérielle en hémoglobine.",
                ],
                title: '',
            },
        ],
    },
    c: {
        globalTexts: [
            'Vous avez besoin d’une aide soutenue pour réaliser les gestes du quotidien et vous devez faire appel à une tierce personne pour vos déplacements.',

            'Il sera donc particulièrement important de veiller à votre sécurité et votre confort.',

            "L’aménagement de l'ensemble des pièces de votre domicile est essentiel pour maintenir votre autonomie. ll est important de s’équiper d’aides techniques pour faciliter votre quotidien ainsi que celui de votre aidant.",

            'L’auxiliaire de vie, peut vous accompagner jour et nuit. Une aide au coucher peut être mise en place afin de vous préparer et aider au coucher, mais aussi intervenir au cours de la nuit pour garantir votre sécurité. Egalement utile pour vous aider à effectuer ces tâches difficiles comme s’habiller et se laver, tout en vous laissant un maximum d’autonomie. Enfin elle vous aidera à garder une alimentation saine en préparant vos repas, mais aussi vous aide à vous alimenter en cas de perte d’autonomie sévère. L’aide soignant peut aussi vous aider lors de la prise de vos médicaments.',

            'Votre pharmacien est là pour vous accompagner dans ces démarches.',
        ],
        sections: [
            {
                texts: [
                    "Le suivi de traitement est un sujet très important notamment dans le cas d'une perte partielle d'autonomie. Avec le pilulier, vous pouvez couper ainsi que broyer en poudre vos médicaments. Nous vous proposons également des solutions d’autodiagnostic tels que les tensiomètres et les oxymètres: tensiomètre de bras, tensiomètre de poignet et oxymètre de pouls pour mesurer avec précision la saturation artérielle en hémoglobine.",
                ],
                title: '',
            },
        ],
    },
};
