import React from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './src/styles/vendors/sanitize.styl';
import './src/styles/vendors/reset.styl';
import './src/styles/global.styl';
import './src/fonts/style.styl';
import './src/styles/texts.styl';

import ReduxProvider from './src/Providers/ReduxProvider';
import { SidebarContextProvider } from './src/views/components/Sidebar/SidebarContext';

export const onServiceWorkerUpdateReady = () => window.location.reload(true);

export const onClientEntry = async () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (typeof window.IntersectionObserver === `undefined`) {
        await import(`intersection-observer`);
        console.log(`# IntersectionObserver is polyfilled!`);
    }
};

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
    return (
        <ReduxProvider>
            <SidebarContextProvider>{element}</SidebarContextProvider>
        </ReduxProvider>
    );
};
