import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    list: [],
    printed: false,
    saved: false,
    sent: false,
};

const cartReducer = createSlice({
    initialState,
    name: 'cart',
    reducers: {
        addToCart: {
            prepare(product) {
                return { payload: { id: product._id, product } };
            },
            reducer(state, action) {
                const { id, product } = action.payload;
                state.list.push({ count: 1, id, product });
            },
        },
        clearCart() {
            return initialState;
        },
        decrementCartItem(state, action) {
            const product = state.list.find((product) => product.id === action.payload);
            if (product) {
                if (product.count > 1) {
                    product.count -= 1;
                }
            }
        },
        incrementCartItem(state, action) {
            const product = state.list.find((product) => product.id === action.payload);
            if (product) {
                product.count += 1;
            }
        },
        printCart(state) {
            state.printed = true;
        },
        removeItemFromCart(state, action) {
            const newArray = [...state.list.filter((product) => product.id !== action.payload)];
            return { ...state, list: newArray };
        },
        saveCart(state) {
            state.saved = true;
        },
        sendCart(state) {
            state.sent = true;
        },
    },
});

export const {
    addToCart,
    clearCart,
    decrementCartItem,
    incrementCartItem,
    printCart,
    removeItemFromCart,
    saveCart,
    sendCart,
} = cartReducer.actions;

export default cartReducer.reducer;
