import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const filtersReducer = createSlice({
    initialState,
    name: 'filters',
    reducers: {
        resetFilters() {
            return [];
        },
        toggleFilter(state, action) {
            const filterExist = state.find((filter) => filter === action.payload);
            if (filterExist) {
                return [...state.filter((filter) => filter !== action.payload)];
            } else {
                state.push(action.payload);
            }
        },
    },
});

export const { resetFilters, toggleFilter } = filtersReducer.actions;

export default filtersReducer.reducer;
