import { createSlice } from '@reduxjs/toolkit';

import { logout } from '../services/admin';

const initialState = {
    error: false,
    loggedIn: false,
    pending: false,
};

const adminReducer = createSlice({
    initialState,
    name: 'admin',
    reducers: {
        adminLogout(state, action) {
            state.loggedIn = false;
            logout();
        },
        logAdmin(state, action) {
            state.loggedIn = action.payload.loggedIn;
        },
    },
});

export const { adminLogout, logAdmin } = adminReducer.actions;

export default adminReducer.reducer;
