import { createSlice } from '@reduxjs/toolkit';

const initialState = { brand: null, supplier: null };

const selectorFiltersReducer = createSlice({
    initialState,
    name: 'selector_filter',
    reducers: {
        resetSelectorFilters() {
            return initialState;
        },
        setBrandFilter(state, action) {
            state.brand = action.payload;
            return state;
        },
        setSupplierFilter(state, action) {
            state.supplier = action.payload;
            return state;
        },
    },
});

export const { resetSelectorFilters, setBrandFilter, setSupplierFilter } = selectorFiltersReducer.actions;

export const brandFilterSelected = (state) => {
    return state?.selectorFilter?.brand;
};

export const supplierFilterSelected = (state) => {
    return state?.selectorFilter?.supplier;
};

export default selectorFiltersReducer.reducer;
