export const formatAnswersSkeleton = (diagnosticCategories) => {
    const answersSkeleton = {};
    diagnosticCategories.forEach((category) => {
        answersSkeleton[category.label] = {};
        category.subCategories.forEach((subCategory) => {
            answersSkeleton[category.label][subCategory.label] = '';
        });
    });
    return answersSkeleton;
};

// user response is a string, possible values are 'a', 'b', 'c'
export const countUserResponseType = (userResponses) => {
    const result = {
        a: 0,
        b: 0,
        c: 0,
    };
    Object.keys(userResponses).forEach((category) => {
        Object.keys(userResponses[category]).forEach((subCategory) => {
            result[userResponses[category][subCategory]] += 1;
        });
    });
    return result;
};

export const countUserResponseByCategory = (userResponses) => {
    const result = {
        a: 0,
        b: 0,
        c: 0,
    };
    Object.keys(userResponses).forEach((subCategory) => {
        result[userResponses[subCategory]] += 1;
    });
    return result;
};

export const getGlobalAutonomy = (userResponses) => {
    const count = countUserResponseType(userResponses);
    if (count.b === 0 && count.c === 0) {
        return {
            chip: 'bonne',
            color: 'green',
            result: 'a',
            texts: [
                'Vous êtes libre de vos mouvements et l’enjeu est de préserver votre qualité de vie. Il sera donc important de veiller à votre sécurité et votre confort.',
                "L’aménagement et l’équipement de votre chambre, de votre salle de bain ou de votre salon, par exemple, est essentiel pour préserver confort et sécurité. D'autres produits pourront par ailleurs soulager votre quotidien et prévenir d'éventuels accidents.",
            ],
            title: 'Votre autonomie est globalement',
        };
    }
    // if (count.b === 1 && count.c === 0) {
    //     return { chip: 'plutôt bonne', color: 'green', result: 'b', title: 'Votre autonomie est globalement' };
    // }
    if (count.b >= 1 && count.c === 0) {
        return {
            chip: 'partiellement limitée',
            color: 'orange',
            result: 'b',
            texts: [
                'Vous rencontrez des difficultés à réaliser les gestes du quotidien et votre mobilité s’en trouve réduite. L’enjeu est de préserver votre qualité de vie et de faciliter vos déplacements.',
                'Il sera donc important de veiller à votre sécurité et votre confort.',
                "L’aménagement de votre chambre, de votre salle de bain ou de votre salon doit être repensé lorsque les gestes de la vie quotidienne deviennent plus difficiles et ainsi limiter les risques d'accident domestique. Des solutions existent également pour appréhender au mieux vos déplacements à l'extérieur et vos activités, le suivi de vos traitements ou bien la prise des repas. ",
            ],
            title: 'Votre autonomie est globalement',
        };
    }
    if (count.c > 0) {
        return {
            chip: 'nécessite une assistance',
            color: 'red',
            result: 'c',
            texts: [
                'Vous avez besoin d’une aide soutenue pour réaliser les gestes du quotidien et vous devez faire appel à une tierce personne pour vos déplacements.',

                'Il sera donc particulièrement important de veiller à votre sécurité et votre confort.',

                "L’aménagement de l'ensemble des pièces de votre domicile est essentiel pour maintenir votre autonomie. ll est important de s’équiper d’aides techniques pour faciliter votre quotidien ainsi que celui de votre aidant.",

                'L’auxiliaire de vie, peut vous accompagner jour et nuit. Une aide au coucher peut être mise en place afin de vous préparer et aider au coucher, mais aussi intervenir au cours de la nuit pour garantir votre sécurité. Egalement utile pour vous aider à effectuer ces tâches difficiles comme s’habiller et se laver, tout en vous laissant un maximum d’autonomie. Enfin elle vous aidera à garder une alimentation saine en préparant vos repas, mais aussi vous aide à vous alimenter en cas de perte d’autonomie sévère. L’aide soignant peut aussi vous aider lors de la prise de vos médicaments.',

                'Votre pharmacien est là pour vous accompagner dans ces démarches. ',
            ],
            title: 'Votre autonomie globale',
        };
    }
};

export const getAutonomyByCategory = (userResponses, categoryId) => {
    const count = countUserResponseByCategory(userResponses[categoryId]);
    if (count.b === 0 && count.c === 0) {
        return { chip: 'bonne', color: 'green', result: 'a', title: 'Votre autonomie est' };
    }
    if (count.b >= 1 && count.c === 0) {
        return {
            chip: 'partiellement limitée',
            color: 'orange',
            result: 'b',
            title: 'Votre autonomie est évaluée comme',
        };
    }
    if (count.c > 0) {
        return { chip: 'nécessite une assistance', color: 'red', result: 'c', title: 'Votre autonomie' };
    }
};
