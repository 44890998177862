import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { initCentralizedImagesDB, initClientDB, initMainDB, initProductDB } from './init';

const itemInitialState = {
    completed: false,
    db: null,
    error: false,
    pending: false,
};

export const databasesReducerInitialState = {
    centralizedImagesDB: {
        ...itemInitialState,
        entriesRemaining: 0,
        totalEntries: 0,
    },
    clientDB: {
        ...itemInitialState,
        entriesRemaining: 0,
        totalEntries: 0,
    },
    mainDB: itemInitialState,
    productDB: {
        ...itemInitialState,
        entriesRemaining: 0,
        totalEntries: 0,
    },
};

export const initDatabaseMain = createAsyncThunk('databases/initMainDB', async (dbNameInfos) => {
    const response = await initMainDB(dbNameInfos);
    return response;
});

export const initDatabaseProduct = createAsyncThunk('databases/initProductDB', async (dbNameInfos, thunkAPI) => {
    const response = await initProductDB(dbNameInfos, thunkAPI);
    return response;
});

export const initDatabaseCentralizedImages = createAsyncThunk(
    'databases/initCentralizedImagesDB',
    async (dbNameInfos, thunkAPI) => {
        const response = await initCentralizedImagesDB(dbNameInfos, thunkAPI);
        return response;
    },
);

export const initDatabaseClient = createAsyncThunk('databases/initClientDB', async (dbNameInfos, thunkAPI) => {
    const response = await initClientDB(dbNameInfos, thunkAPI);
    return response;
});

const databasesReducer = createSlice({
    extraReducers: {
        [initDatabaseMain.pending]: (state) => {
            state.mainDB.pending = true;
            state.mainDB.completed = false;
        },
        [initDatabaseMain.error]: (state) => {
            state.mainDB.error = true;
            state.mainDB.pending = false;
        },
        [initDatabaseMain.fulfilled]: (state, action) => {
            state.mainDB.pending = false;
            state.mainDB.completed = action.payload;
        },
        [initDatabaseClient.pending]: (state) => {
            state.clientDB.pending = true;
            state.clientDB.completed = false;
        },
        [initDatabaseClient.error]: (state) => {
            state.clientDB.error = true;
            state.clientDB.pending = false;
        },
        [initDatabaseClient.fulfilled]: (state, action) => {
            state.clientDB.pending = false;
            state.clientDB.completed = action.payload;
        },
        [initDatabaseProduct.pending]: (state) => {
            state.productDB.pending = true;
            state.productDB.completed = false;
        },
        [initDatabaseProduct.error]: (state) => {
            state.productDB.error = true;
            state.productDB.pending = false;
        },
        [initDatabaseProduct.fulfilled]: (state, action) => {
            state.productDB.pending = false;
            state.productDB.completed = action.payload;
            state.productDB.entriesRemaining = 0;
        },
        [initDatabaseCentralizedImages.pending]: (state) => {
            state.centralizedImagesDB.pending = true;
            state.centralizedImagesDB.completed = false;
        },
        [initDatabaseCentralizedImages.error]: (state) => {
            state.centralizedImagesDB.error = true;
            state.centralizedImagesDB.pending = false;
        },
        [initDatabaseCentralizedImages.fulfilled]: (state, action) => {
            state.centralizedImagesDB.pending = false;
            state.centralizedImagesDB.completed = action.payload;
            state.centralizedImagesDB.entriesRemaining = 0;
        },
    },
    initialState: databasesReducerInitialState,
    name: 'databases',
    reducers: {
        setRemainingEntries(state, action) {
            state[action.payload.db].entriesRemaining = action.payload.nbEntries;
        },
        setTotalEntries(state, action) {
            state[action.payload.db].totalEntries = action.payload.totalEntries;
        },
    },
});

export const { setRemainingEntries, setTotalEntries } = databasesReducer.actions;

export default databasesReducer.reducer;
