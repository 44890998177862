import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import propTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';

import rootReducer from '../reducers';

const ReduxProvider = ({ children }) => {
    const persistConfig = {
        key: 'medikiosk',
        storage,
        version: 2,
        whitelist: ['databases', 'admin', 'cart', 'productState', 'filters', 'supplier_filter', 'diagnostic'],
    };

    const persistedReducer = persistReducer(persistConfig, rootReducer);

    const store = configureStore({
        middleware: getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
        reducer: persistedReducer,
    });

    const persistor = persistStore(store);

    if (process.env.NODE_ENV === 'development' && module.hot) {
        module.hot.accept('../reducers', () => {
            const newRootReducer = require('../reducers').default;
            store.replaceReducer(persistReducer(persistConfig, newRootReducer));
        });
    }

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {children}
            </PersistGate>
        </Provider>
    );
};

ReduxProvider.propTypes = {
    children: propTypes.node.isRequired,
};

export default ReduxProvider;
