module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"l":"(max-width: 1536px)","md":"(max-width: 1024px)","sm":"(max-width: 720px)","xs":"(max-width: 320px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#5AA7F1","description":"","display":"minimal-ui","icon":"src/images/logo/icotype_fondfonce@1x.png","icons":[{"sizes":"48x48","src":"/favicons/icon-48x48.png","type":"image/png"},{"sizes":"72x72","src":"/favicons/icon-72x72.png","type":"image/png"},{"sizes":"96x96","src":"/favicons/icon-96x96.png","type":"image/png"},{"sizes":"144x144","src":"/favicons/icon-144x144.png","type":"image/png"},{"sizes":"192x192","src":"/favicons/icon-192x192.png","type":"image/png"},{"sizes":"256x256","src":"/favicons/icon-256x256.png","type":"image/png"},{"sizes":"384x384","src":"/favicons/icon-384x384.png","type":"image/png"},{"sizes":"512x512","src":"/favicons/icon-512x512.png","type":"image/png"}],"lang":"fr","localize":[{"description":"","lang":"en","name":"Medikiosk","short_name":"Medikiosk","start_url":"/en/"}],"name":"Medikiosk","short_name":"Medikiosk","start_url":"/","theme_color":"#DEEDFC","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7b0422a8f42a56273563561e4e4190b6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
