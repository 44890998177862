import { createSlice } from '@reduxjs/toolkit';

import { diagnosticCategories } from './constants/categories';

const categories = diagnosticCategories;

const initialState = {
    currentCategoryIndex: 0,
    currentSubcategoryIndex: 0,
    isCompleted: false,
    isDirty: false,
    userResponses: {}, // Format: { categoryId: { subcategoryId: responseId }}
};

const diagnosticReducer = createSlice({
    initialState,
    name: 'diagnostic',
    reducers: {
        clearDiagnostic() {
            return initialState;
        },
        nextStep(state) {
            const currentCategory = categories[state.currentCategoryIndex];

            if (state.currentSubcategoryIndex < currentCategory.subCategories.length - 1) {
                state.currentSubcategoryIndex += 1;
            } else if (state.currentCategoryIndex < categories.length - 1) {
                state.currentCategoryIndex += 1;
                state.currentSubcategoryIndex = 0;
            } else if (
                state.currentCategoryIndex === categories.length - 1 &&
                state.currentSubcategoryIndex === currentCategory.subCategories.length - 1
            ) {
                state.isCompleted = true;
            }
        },
        previousStep(state) {
            if (state.currentSubcategoryIndex > 0) {
                state.currentSubcategoryIndex -= 1;
            } else if (state.currentCategoryIndex > 0) {
                state.currentCategoryIndex -= 1;

                const previousCategory = categories[state.currentCategoryIndex];

                state.currentSubcategoryIndex = previousCategory.subCategories.length - 1;
            }
        },
        selectResponse(state, action) {
            const { categoryId, responseId, subcategoryId } = action.payload;

            if (!state.userResponses[categoryId]) {
                state.userResponses[categoryId] = {};
            }
            state.userResponses[categoryId][subcategoryId] = responseId;
        },
        startDiagnostic(state) {
            state.isDirty = true;
        },
    },
});

export const {
    clearDiagnostic,
    decrementStep,
    incrementStep,
    nextStep,
    previousStep,
    selectResponse,
    startDiagnostic,
} = diagnosticReducer.actions;

export default diagnosticReducer.reducer;
